<template>
  <div id="order-view-action-accept">
    <b-button
      v-if="quoteSelected && (quoteSelected.paymentIntent === null || paymentError)"
      id="order-view-action-awaiting-customer-validation-accept"
      v-ripple.400
      variant="success"
      class="mb-75"
      block
      :disabled="quoteSelected.quote.priceIncludingTaxes === 0"
      @click.prevent="acceptEdition = true"
    >
      <feather-icon :icon="btnIcon || 'CheckIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.accept') }} </span>
    </b-button>

    <app-sidebar-form
      form-name="order-view-action-accept"
      :edition="acceptEdition"
      edition-mode-title="edit"
      :title="$tc('payment.method.title', 1)"
      :entity="{
        ...quoteSelected.quote.paymentMethodId,
        ...quoteSelected.quote.mandateAccepted,
        ...quoteSelected.quote.termsOfSaleAccepted,
      }"
      :submit-btn-text="$t('action.accept')"
      submit-btn-icon="CheckIcon"
      submit-btn-variant="success"
      no-pristine
      @update:edition="acceptEdition = $event"
      @update:entity="paidQuotation($event)"
    >
      <template #fields="data">
        <b-row>
          <b-col v-if="quoteSelected.quote.paymentTerm !== 'on_contract'" cols="12">
            <!-- paymentMethodId -->
            <validation-provider
              #default="{ errors }"
              :name="$tc('payment.method.title', 1)"
              rules="required"
              vid="order-view-action-accept-payment-methods-type-provider"
            >
              <b-form-group
                :label="$tc('payment.method.title', 1)"
                label-for="order-view-action-accept-payment-methods-type"
                class="validation-required"
              >
                <b-overlay :show="paymentMethodsLoading">
                  <v-select
                    v-model="data.item.paymentMethodId"
                    input-id="order-view-action-accept-payment-methods-type"
                    :options="paymentMethods"
                    :reduce="name => name.id"
                    label="type"
                    :placeholder="$tc('payment.method.title', 1)"
                    :clearable="false"
                    :searchable="false"
                    class="select-size-md"
                  >
                    <template #option="{ type, lastCharacters }"> {{ type | enumTranslate('payment_methods_type') }} -
                      **** {{ lastCharacters }}
                    </template>
                    <template #selected-option="{ type, lastCharacters }">
                      {{ type | enumTranslate('payment_methods_type') }} - **** {{ lastCharacters }}
                    </template>
                    <template #no-options>
                      {{ $t('common.no_option_found') }}
                    </template>
                  </v-select>
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <!-- terms-mandateAccepted-->
            <validation-provider
              v-if="paymentMethodTypeById(data.item.paymentMethodId) === 'sepa_debit'"
              #default="{ errors }"
              :name="$tc('payment.method.sepa.title', 1)"
              rules="required"
              vid="order-view-action-accept-terms-sepa-provider"
            >
              <b-form-group
                :label="$t('payment.method.sepa.title')"
                label-for="order-view-action-accept-terms-sepa"
                class="validation-required"
              >
                <b-form-checkbox
                  id="order-view-action-accept-terms-sepa"
                  v-model="data.item.mandateAccepted"
                  :state="errors[0] ? false : null"
                  class="mb-1"
                >
                  {{ $t('payment.method.sepa.text') }}
                </b-form-checkbox>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <!-- tos-->
            <validation-provider
              #default="{ errors }"
              :name="$t('payment.method.tos.title')"
              rules="required"
              vid="order-view-action-accept-terms-tos-provider"
            >
              <b-form-group
                label-for="order-view-action-accept-terms-tos"
                class="validation-required"
              >
                <b-form-checkbox
                  v-model="data.item.termsOfSaleAccepted"
                  :state="errors.length > 0 ? false : null"
                  value="commercialTerms"
                >
                  <a :href="quoteSelected.quote.organization.cgvUrl" target="_blank">{{
                    $t('payment.method.tos.title')
                  }}</a>
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
// AWAIT API fix
import AppSidebarForm from '@/components/AppSidebarForm.vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import stripe from '@/libs/stripes'

import { patchQuoteValidateRequest, postQuoteValidateRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewActionAccept',

  components: {
    AppSidebarForm,
  },

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      acceptEdition: false,
    }
  },

  computed: {
    ...mapState('paymentMethod', ['paymentMethods', 'paymentMethodsLoading']),
    ...mapState('quote', ['quoteSelected']),
    ...mapState('auth', ['selectedOrganization']),
    ...mapGetters('paymentMethod', ['paymentMethodTypeById']),
    ...mapGetters('quote', ['paymentError']),

    ...mapFields('quote', ['quoteLoading']),
  },

  mounted() {
    // Chargement des méthodes de paiement que pour le client pour générer la liste
    if (this.quoteSelected.quote.client.id === this.selectedOrganization.id) {
      this.fetchPaymentMethods()
    }
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),
    ...mapActions('paymentMethod', ['fetchPaymentMethods']),

    paidQuotation(payment) {
      this.quoteLoading = true
      if (!this.paymentError) {
        return postQuoteValidateRequest(
          this.quoteSelected.quote.id,
          { ...payment, paymentUrl: window.location.href },
          this.$store.getters['quote/quoteTitleAndRef'],
          this.$t('request.action.validate'),
        )
          .then(response => {
            const intent = response?.data?.length ? response.data[0] : null
            if (intent?.requiresAction) {
              this.goStripe(intent)
              return false
            }
            return true
          })
          .then(noStripe => {
            noStripe
              && this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
                this.quoteLoading = false
              })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }
      return patchQuoteValidateRequest(
        this.quoteSelected.quote.id,
        { ...payment, paymentUrl: window.location.href, termsOfSaleAccepted: true, termsOfSellerAccepted: true },
        this.$store.getters['quote/quoteTitleAndRef'],
      )
        .then(response => {
          const intent = response?.data?.length ? response.data[0] : null
          if (intent?.requiresAction) {
            this.goStripe(intent)
            return false
          }
          return true
        })
        .then(noStripe => {
          noStripe
            && this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },

    goStripe(intent) {
      stripe
        .then(stripeResponse => {
          stripeResponse.handleCardAction(`${intent.paymentIntentClientSecret}`).then(paymentIntent => {
            if (paymentIntent) {
              patchQuoteValidateRequest(this.quoteSelected.quote.id, { termsOfSaleAccepted: true }, this.$store.getters['quote/quoteTitleAndRef'])
                .then(() => {
                  this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id })
                })
                .finally(() => {
                  this.quoteLoading = false
                })
            }
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>
